<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="Vui lòng chọn" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>Thỏa thuận mở cửa hàng "UniLive"</h2>
		<p>UniLive là viết tắt của Pix Technology PTE. LTD.
			Một thỏa thuận điện tử có hiệu lực pháp lý được thỏa thuận giữa LTD. (sau đây gọi là "chúng tôi") và bạn (bạn là
			thể nhân, pháp nhân hoặc tổ chức khác) điều chỉnh quyền và nghĩa vụ của các bên, sau đây gọi là "Thỏa thuận này".
			Bằng cách đánh dấu "Tôi đồng ý" hoặc nhấp vào nút "Tôi đã đọc và tuân thủ Thỏa thuận", bạn có nghĩa là bạn đã đọc
			kỹ, hiểu đầy đủ và hoàn toàn chấp nhận tất cả các điều khoản của Thỏa thuận này mà không cần bảo lưu.
		</p>
		<h3>Mục đích giao thức đầu tiên</h3>
		<p>Thỏa thuận này nhằm làm rõ quyền và nghĩa vụ giữa người bán và nền tảng UniLive, điều chỉnh hành vi kinh doanh
			của người bán, bảo vệ quyền và lợi ích của người tiêu dùng, thúc đẩy sự phát triển lành mạnh của nền tảng UniLive
			và đảm bảo quyền và lợi ích hợp pháp của các bên trong giao dịch.
		</p>
		<h3>Định nghĩa thứ hai</h3>
		<p>1, UniLive: là bên vận hành Ứng dụng và các dịch vụ liên quan, nền tảng cung cấp giao dịch trực tuyến và hỗ trợ
			dịch vụ.
		</p>
		<p>2,Thương gia: là cá nhân hoặc doanh nghiệp đăng ký và thực hiện các hoạt động thương mại trên UniLive, bao gồm
			nhưng không giới hạn ở các hộ kinh doanh cá nhân, công ty và pháp nhân khác.
		</p>
		<p>3,Người tiêu dùng: là người dùng mua hàng hóa hoặc dịch vụ trên UniLive.
		</p>
		<p>
			Yêu cầu đăng ký: Người bán phải điền thông tin cá nhân hoặc doanh nghiệp xác thực và chính xác trên UniLive, bao
			gồm tên, chi tiết liên lạc, giấy phép kinh doanh, giấy chứng nhận đăng ký thuế. Người bán phải đảm bảo rằng thông
			tin đăng ký được cập nhật kịp thời, chẳng hạn như thông tin thay đổi, phải được cập nhật trong vòng 48 giờ.
		</p>
		<p>
			Quy trình đánh giá: UniLive sẽ tiến hành đánh giá thông tin do người bán gửi, thời gian đánh giá thường là 3-5
			ngày
			làm việc. Sau khi xét duyệt thông qua, thương gia sẽ nhận được thông báo xác nhận, chức năng mở cửa hàng chính
			thức
			được sử dụng.
		</p>
		<p>3, Thiết lập cửa hàng: Người bán cần thiết lập cửa hàng trên UniLive, bao gồm tên cửa hàng, logo, giới thiệu vắn
			tắt, phân loại hàng hóa, v.v., để đảm bảo thông tin phù hợp với các quy định của nền tảng.
		</p>
		<h3>Quyền và nghĩa vụ của doanh nghiệp</h3>
		<p>
			1. Công bố thông tin hàng hóa và bán hàng trên UniLive, hưởng thụ các dịch vụ do nền tảng cung cấp.
		</p>
		<p>2, Tham gia vào các chương trình khuyến mãi và tiếp thị của UniLive để tăng khả năng tiếp xúc.</p>
		<p>3, Nhận dữ liệu bán hàng, phản hồi của người dùng và báo cáo phân tích thị trường để tối ưu hóa chiến lược kinh
			doanh.</p>
		<p>4, Tuân thủ hàng hóa: Đảm bảo tính hợp pháp, xác thực và tuân thủ của hàng hóa được bán và không bán hàng giả và
			kém chất lượng. </p>
		<p>5, Cập nhật thông tin: Cập nhật thông tin hàng hóa thường xuyên để đảm bảo giá cả, hàng tồn kho, thông tin phân
			phối là chính xác và rõ ràng.</p>
		<p>6, Tuân thủ các quy tắc nền tảng: Tuân thủ các quy định quản lý và quy tắc hoạt động khác nhau của UniLive, tham
			gia đào tạo nền tảng và nâng cao năng lực kinh doanh.</p>
		<h3>Quyền và nghĩa vụ của UniLive</h3>
		<p>1, Kiểm toán và quản lý thông tin do người bán công bố, đảm bảo tuân thủ thông tin.</p>
		<p>2,Thực hiện các biện pháp tương ứng đối với các thương gia vi phạm thỏa thuận, bao gồm nhưng không giới hạn ở
			cảnh
			báo, đình chỉ hoặc chấm dứt cửa hàng của họ cho đến khi hành động pháp lý. </p>
		<p>3, theo điều kiện thị trường và thay đổi pháp luật và quy định, thường xuyên cập nhật chính sách nền tảng, các
			thương gia nên biết và tuân thủ kịp thời.</p>
		<p>4, cung cấp nền tảng giao dịch an toàn và ổn định, đảm bảo an toàn giao dịch cho người bán và người tiêu dùng,
			bảo vệ chống lại lừa đảo và các hành vi vi phạm pháp luật khác. </p>
		<p>5, xử lý kịp thời các vấn đề và phản hồi của người bán, cung cấp hỗ trợ kỹ thuật và đào tạo cần thiết.</p>
		<p>6, thường xuyên cung cấp phân tích dữ liệu kinh doanh, báo cáo xu hướng thị trường và khuyến nghị quảng bá cho
			các thương gia, giúp họ nâng cao hiệu suất.</p>
		<h3>Trách nhiệm pháp lý</h3>
		<p>
			1,Nếu người bán vi phạm bất kỳ điều khoản nào của Thỏa thuận này, UniLive có quyền yêu cầu người bán thực hiện
			chỉnh
			sửa và áp dụng các biện pháp trừng phạt tương ứng, bao gồm nhưng không giới hạn ở việc hạ cấp cửa hàng, hạn chế
			bán hàng và chấm dứt dịch vụ.
		</p>
		<p>2, khiếu nại của người tiêu dùng do nguyên nhân của người bán, người bán phải chịu trách nhiệm pháp lý và trách
			nhiệm bồi thường tương ứng. </p>
		<p>3 Nếu UniLive không cung cấp dịch vụ theo thỏa thuận, người bán có thể yêu cầu UniLive bồi thường thiệt hại, số
			tiền bồi thường cụ thể do hai bên thương lượng xác định.</p>
		<h3>Thay đổi và hủy bỏ thỏa thuận</h3>
		<p>Bất kỳ sửa đổi hoặc thay đổi nào đối với Thỏa thuận này có thể được dỡ bỏ bằng sự đồng thuận của các bên.</p>
		<p>2, uniLive có quyền định kỳ sửa đổi thỏa thuận theo các yếu tố như luật pháp và quy định, thay đổi thị trường và
			thông báo kịp thời cho người bán. </p>
		<p>3. Người bán muốn hủy bỏ Thỏa thuận này phải thông báo bằng văn bản cho UniLive trước 7 ngày và xử lý đúng các
			đơn
			hàng chưa hoàn thành và khiếu nại của người tiêu dùng trước khi hủy bỏ. </p>
		<p>4, UniLive có quyền hủy bỏ Thỏa thuận này ngay lập tức mà không cần thông báo trước khi phát hiện ra vi phạm
			nghiêm trọng của người bán. </p>
		<h3>Điều 8 Các điều khoản khác</h3>
		<p>1,Điều khoản bảo mật: Các bên phải giữ bí mật thương mại và các thông tin khác đã biết trong quá trình thực hiện
			thỏa thuận và không được tiết lộ cho bên thứ ba.
		</p>
		<p>2,Bất khả kháng: Trong trường hợp không thể thực hiện thỏa thuận do các yếu tố bất khả kháng (như thiên tai,
			chiến
			tranh, thay đổi chính sách, v.v.), hai bên không chịu trách nhiệm về vi phạm hợp đồng.
		</p>
	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/StoreOpeningAgreementZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/StoreOpeningAgreementEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/StoreOpeningAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/StoreOpeningAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/StoreOpeningAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/StoreOpeningAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/StoreOpeningAgreementTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/StoreOpeningAgreementZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/StoreOpeningAgreementEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/StoreOpeningAgreementKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/StoreOpeningAgreementJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/StoreOpeningAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/StoreOpeningAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/StoreOpeningAgreementTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}

li {
	font-size: 0.3rem;
}
</style>